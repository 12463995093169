@import url(https://fonts.googleapis.com/css?family=Raleway);



.figure figcaption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.figure h3,
.figure h5 {
    margin: 0;
    opacity: 0;
    letter-spacing: 1px;
}

.figure h3 {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
    margin-bottom: 5px;
}

.figure h5 {
    font-weight: normal;
    background-color: #db9846;
    padding: 3px 5px;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.figure a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.figure:hover:before,
.figure.hover:before {
    top: 10px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}


.figure:hover h3,
.figure.hover h3,
.figure:hover h5,
.figure.hover h5 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.figure:hover h3,
.figure.hover h3 {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.figure:hover h5,
.figure.hover h5 {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}